<template>
  <div class="p-3">
    <div class="row">
      <div class="col-12">
        <h4>Change access of {{ patientID }}</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <b-card title="Patient">
          <b-card-text v-if="!patientLoaded">
            <b-spinner></b-spinner>
          </b-card-text>
          <b-card-text v-if="patientLoaded">
            <p><b>ID</b>: {{ patient.user_id }}</p>
            <p><b>Name</b>: {{ patient.name }}</p>
          </b-card-text>
        </b-card>
      </div>

      <div class="col-12 mt-3">
        <b-card title="Professionals" v-if="patientLoaded">
          <b-card-text v-if="!professionalsLoaded">
            <b-spinner></b-spinner>
          </b-card-text>
          <b-card-text v-if="professionalsLoaded">
            <b-table-simple hover small responsive>
              <b-thead>
                <b-tr>
                  <b-th>ID</b-th>
                  <b-th>Username</b-th>
                  <b-th>Name</b-th>
                  <b-th class="text-center">Shared</b-th>
                  <b-th class="text-center">Owner</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(professional,index) in professionals" :key="index">
                  <b-td>{{ professional.id }}</b-td>
                  <b-td>{{ professional.username }}</b-td>
                  <b-td>{{ professional.firstname }} {{ professional.lastname }}</b-td>
                  <b-td>
                    <b-badge variant="success" role="button"
                             @click="removeShareModal(professional)"
                             v-if="professional.is_shared">Shared</b-badge>
                    <b-badge variant="primary" role="button"
                             @click="shareModal(professional)"
                             v-if="!professional.is_shared">Share with {{ professional.firstname }}</b-badge>
                  </b-td>
                  <b-td>
                    <b-badge variant="success"
                             v-if="professional.is_owner && professional.available_for_ownership">Is owner</b-badge>
                    <b-badge variant="warning"
                             v-if="professional.is_owner && !professional.available_for_ownership">Is owner but it shouldn't</b-badge>
                    <b-badge variant="primary" role="button" @click="makeOwnerModal(professional)"
                             v-if="!professional.is_owner && professional.available_for_ownership">Make owner</b-badge>
                    <b-badge v-if="!professional.is_owner && !professional.available_for_ownership">Cannot be owner</b-badge>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-text>
        </b-card>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MemberChangeOwner',
  components: {},
  filters: {},
  data() {
    return {
      patientID: null,
      professionals: [],
      professionalsLoaded: false,
      patient: {},
      patientLoaded: false,
      currentShares: [],
    };
  },
  computed: {},
  beforeMount() {
    this.patientID = this.$route.params.patientID;
    this.fetchPatient();
  },
  methods: {
    makeOwnerModal(professional) {
      this.$bvModal.msgBoxConfirm(
        `id: ${professional.id} | username:  ${professional.username}`,
        { title: `Change owner to ${professional.username} ?` },
      )
        .then(confirmed => {
          if (!confirmed) {
            return;
          }
          this.makeOwner(professional);
        })
        .catch(e => {
          this.$noty.error(e);
        });
    },
    makeOwner(professional) {
      axios.put(
        `/v1/patients/${this.patientID}`,
        { user_id: this.patientID, owner_id: professional.id },
      ).then(() => {
        this.patient.owner_id = professional.id;
        this.$noty.success(`${professional.username} is now the owner`);
      }).catch(e => {
        this.$noty.error(e.response.data.error_description);
      });
    },

    shareModal(professional) {
      this.$bvModal.msgBoxConfirm(
        `id: ${professional.id} | username:  ${professional.username}`,
        { title: `Share member with ${professional.username} ?` },
      )
        .then(confirmed => {
          if (!confirmed) {
            return;
          }
          this.currentShares.push(professional.id);
          this.shareWithProfessional(professional, true);
        })
        .catch(e => {
          this.$noty.error(e);
        });
    },
    removeShareModal(professional) {
      this.$bvModal.msgBoxConfirm(
        `id: ${professional.id} | username:  ${professional.username}`,
        { title: `Remove share with ${professional.username} ?` },
      )
        .then(confirmed => {
          if (!confirmed) {
            return;
          }
          this.currentShares = this.currentShares.filter(professionalID => professionalID !== professional.id);
          this.shareWithProfessional(professional, false);
        })
        .catch(e => {
          this.$noty.error(e);
        });
    },
    shareWithProfessional(professional, add) {
      axios.post(
        `/v1/patients/${this.patientID}/share?client=admin-ui`,
        { professionals: this.currentShares, institution_id: this.patient.institution_id },
      ).then(() => {
        if (add) {
          this.$noty.success(`${this.patient.name} shared with ${professional.username}`);
          return;
        }
        this.$noty.success(`${this.patient.name} is no longer shared with ${professional.username}`);
      }).catch(e => {
        this.$noty.error(e.response.data.error_description);
      });
    },

    fetchPatient() {
      axios.get(
        `/v1/patients/${this.patientID}?client=admin-ui`,
        { params: { includes: 'problematic_side,clinical_information,institution' } },
      ).then(({ data }) => {
        this.patient = data.profile;
        data.profile.professionals.forEach(v => {
          this.currentShares.push(v.user_id);
        });
        this.patientLoaded = true;
        this.fetchActiveTherapists();
      });
    },

    fetchActiveTherapists() {
      axios.get('/v2/therapists', { params: { institution_id: this.patient.institution_id } }).then(({ data }) => {
        this.professionals = data.therapists;
        this.fetchOwnerTherapists();
      }).catch(e => {
        this.$noty.error(e.response.data.error_description);
      });
    },

    fetchOwnerTherapists() {
      const params = {
        institution_id: this.patient.institution_id,
        unit: this.patient.unit,
      };

      if (this.patient.user_metadata?.geographical_state) {
        params.with_license_state = this.patient.user_metadata.geographical_state;
      }

      axios.get('/v2/therapists/treatment', { params }).then(({ data }) => {
        this.professionals.map(professional => {
          professional.available_for_ownership = !!data.state_therapists?.find(v => v.id === professional.id);
          professional.is_shared = this.currentShares.filter(v => v === professional.id).length > 0;
          professional.is_owner = this.patient.owner_id === professional.id;

          professional.sorting_weight = 0;
          if (professional.is_owner) {
            professional.sorting_weight += 50;
          }
          if (professional.is_shared) {
            professional.sorting_weight += 20;
          }
          if (professional.available_for_ownership) {
            professional.sorting_weight += 20;
          }

          return professional;
        });

        this.professionals.sort((a, b) => b.sorting_weight - a.sorting_weight);

        this.professionalsLoaded = true;
      }).catch(e => {
        this.$noty.error(e.response.data.error_description);
      });
    },
  },
  watch: {},
};
</script>
